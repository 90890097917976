<template>
  <div class="home">
    <ExamImage :idUserExam="$route.params.idUserExam"/>
  </div>
</template>

<script>
// @ is an alias to /src
import ExamImage from '@/components/userexam/userexamPreview/UserExamImageResult'

export default {
  name: 'ExamImageView',
  components: {
    ExamImage
  }
}
</script>
